import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Login.scss';
import person from '../../common/icons/40.png';
import person1 from '../../common/icons/41 (1).png';
import { useDispatch, useSelector } from 'react-redux';
import {
	carsoleImage,
	selectCandidateDetails,
	Logo,
	Image,
} from '../../store/candidates/_candidates.slice';
const Login = () => {
	const dispatch = useDispatch();
	const candidateData = useSelector(selectCandidateDetails);
	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, // Enable autoplay
		autoplaySpeed: 200, // Set autoplay speed in milliseconds
		nextArrow: <></>,
	};
	const theme = useTheme();
	const carsole = useSelector(carsoleImage);
	const logo = useSelector(Logo);
	const image = useSelector(Image);
	console.log(logo);
	return (
		<Grid container justifyContent="center" className="login">
			<Grid item xs={12} sm={12} md={12} lg={8} xl={8} className="login-image">
				<Slider {...settings}>
					{carsole?.map((val: any) => (
						<div className="person-image-head">
							<img src={val} className="person-image" />
						</div>
					))}
				</Slider>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}
				justifyContent="center"
				display={'flex'}
				alignItems={'center'}
				// className="login-form-main"
			>
				<LoginForm logo={logo} />
			</Grid>
		</Grid>
	);
};
export default Login;
