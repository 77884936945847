import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import person from '../../common/icons/volunteer.png';
import donationImg from '../../common/icons/approved-icon-style-vector.jpg';
import donors from '../../../common/icons/donors.png';
import teamMembers from '../../common/icons/png-clipart-petition-others-angle-text-thumbnail.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../common/constants/Routes';

import moment from 'moment';
import theme from '../../common/theme';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../common/ui/TableComponent';
import { GridColDef } from '@mui/x-data-grid';
import {
	PetetionListRequest,
	selectPetetionListInfo,
} from '../../store/petetions/_petetion.slice';
import cryptoEncryptionDecryption from '../../common/crypto';
import { publicsListInProgress } from '../../store/public/_public.slice';
import DashboardCount from './DashboardCount';

function DashboardScreen() {
	const [tableRow, setTableRow] = useState([]);
	const { t } = useTranslation();
	const petetionList = useSelector(selectPetetionListInfo);
	const dispatch = useDispatch();
	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);
	console.log('dfa', petetionList);
	const columns = [
		{
			headerName: t('FULL_NAME'),
			field: 'full_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('EMAIL'),
			field: 'email',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('MOBILE_NUMBER'),
			field: 'mobile',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('ADDRESS'),
			field: 'address',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},

		{
			headerName: t('CANDIDATE_NAME'),
			field: 'candidate_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			field: 'subject',
			headerName: t('SUBJECT'),
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('DESCRIPTION'),
			field: 'description',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('STATUS'),
			field: 'status_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
	];

	const handlePagination = (data: any) => {
		console.log(data?.page, data);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: data?.page + 1,
			pagination_required: true,
			petition_type: '',
		};
		dispatch(PetetionListRequest(payloads as any));
	};
	useEffect(() => {
		// const payload = { petetion_id: 'd53d88a5-3a96-4808-92ac-5a5131184418' };
		// dispatch(PetetionDetailRequest(payload as any));
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: 1,
			pagination: true,
			petition_type: '',
		};

		dispatch(PetetionListRequest(payloads as any));
	}, []);
	return (
		<div>
			<Grid
				item
				xs={12}
				container
				display="flex"
				// justifyContent="space-around"
				className="dashboard-grid"
			>
				<DashboardCount
					petetionStatus={''}
					image={teamMembers}
					className={'team-member-grid'}
					title={t('NO_OF_PETITION')}
					count={petetionList?.totalRecords}
				/>
				<DashboardCount
					petetionStatus={1}
					image={donationImg}
					className={'Donations-grid'}
					title={`${t('PETITIONS')} - ${t('PENDING')}`}
					count={petetionList?.statusCount?.pending}
				/>
				<DashboardCount
					petetionStatus={2}
					image={donationImg}
					className={'Volunteers-grid'}
					title={t('PETITION_IN_PROGRESS')}
					count={petetionList?.statusCount?.inprogress}
				/>
				<DashboardCount
					petetionStatus={3}
					image={donationImg}
					className={'Doners-grid'}
					title={`${t('PETITIONS')} - ${t('FORWARD')}`}
					count={petetionList?.statusCount?.forwarded}
				/>
				<DashboardCount
					petetionStatus={4}
					image={donationImg}
					className={'Volunteers-grid'}
					title={`${t('PETITIONS')} - ${t('RESOLVED')}`}
					count={petetionList?.statusCount?.resolved}
				/>
				<DashboardCount
					petetionStatus={5}
					image={donationImg}
					className={'Donations-grid'}
					title={`${t('PETITIONS')} - ${t('REJECT')}`}
					count={petetionList?.statusCount?.reject}
				/>
				<Grid item xs={12} className="mx-10">
					<Typography
						color={theme.palette.common.black}
						fontSize={20}
						textAlign={'left'}
					>
						{t('RECENT_PETITION')}
					</Typography>
					<TableComponent
						rows={petetionList?.data || []}
						columns={columns}
						className={'my-5'}
						type="dashboard"
						totalRecords={petetionList?.totalRecords || 0}
						handlePagination={handlePagination}
						paginationModel={{
							page: Number(petetionList?.currentPage) - 1 || 0,
							pageSize: Number(petetionList?.pagesize) || 5,
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default DashboardScreen;
